// import { Container, Col, Row } from "react-bootstrap";
// import Link from "next/link";
// import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import * as actions from "../../../redux/actions/hashtageAction";
// import { FormattedMessage } from "react-intl";
import MobileMenuBottom from "./bottomMenu";
// import ClevertapReact from "utils/clevertapHelper";
// import { MyLink } from "api/cleverTap";
//import Script from "next/script";

const Footer = ({}) => {
  var dateYear = new Date();
  dateYear = dateYear.getFullYear();
  const dispatch = useDispatch();
  const router = useRouter();
  const [showFooter, setShowFooter] = useState(true);
  // const { hashtag } = useSelector(({ HashtagReducer }) => HashtagReducer);

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    if (router.asPath === "/about") {
      setActiveLink("about");
    } else if (router.asPath === "/privacy-policy") {
      setActiveLink("privacy-policy");
    } else if (router.asPath === "/terms-of-use") {
      setActiveLink("terms-of-use");
    } else if (router.asPath === "/contact") {
      setActiveLink("contact");
    }
  }, []);
  // useEffect(() => {
  //   dispatch(actions.getHashtag());
  // }, [dispatch]);

  useEffect(
    () =>
      setShowFooter(
        router.pathname !== "/login" &&
          router.pathname !== "/cricket-page" &&
          router.pathname !== "/vs/header_lt" &&
          router.pathname !== "/preferences" &&
          !router.pathname.includes("/app-view") &&
          !router.pathname.includes("/liveblog") &&
          !router.pathname.includes("/faq-app") &&
          !router.pathname.includes("/quiz/terms-and-conditions") &&
          !router.pathname.includes("/amp/") &&
          !(router?.query?.shortId?.length >= 0)
      ),
    [router.pathname]
  );
  // useEffect(() => {
  //   console.log(
  //     "router?.pathname, router.query",
  //     router.query,
  //     router.pathname !== "/login" &&
  //       router.pathname !== "/preferences" &&
  //       !router?.query?.shortId?.length >= 0,
  //     router?.query?.shortId?.length,
  //     !router?.query?.shortId?.length >= 0
  //   );
  // }, [router?.pathname, router.query]);
  // useEffect(() => {
  //   if (router.pathname === "/login" && router.pathname === "/preferences") {
  //     setShowFooter(false);
  //   }else{
  //     setShowFooter(true);
  //   }
  // }, [router.pathname]);

  return (
    <>
      {showFooter && (
        <footer className="footer-main">
          <div className="footer-main-row">
            <div className="firstColumn">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "white",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://static.thelallantop.com/images/post/1707895067525_frame.webp"
                    }
                    loading="lazy"
                    className="bottom-items-logo-desktop"
                    alt="desktop-llt-logo"
                  ></img>
                  <br></br>
                  <h4 style={{ textAlign: "center" }}>Social Media</h4>
                  <ul className="social-link-list mb-4 pb-3">
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.facebook.com/thelallantop"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Facebook Page"
                      >
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://twitter.com/TheLallantop"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Twitter Page"
                      >
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.instagram.com/thelallantop/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Instagram Page"
                      >
                        <span className="icon-instagram"></span>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.youtube.com/channel/UCx8Z14PpntdaxCt2hakbQLQ"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Youtube Page"
                      >
                        <span className="icon-vidos"></span>
                      </a>
                    </li>
                  </ul>
                  <h4 style={{ textAlign: "center" }}>Download Apps</h4>
                  <ul className="social-link-list mb-4 pb-3">
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.thelallantop&hl=en&gl=US&pli=1"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Google Play Page"
                      >
                        <img
                          src={
                            "https://static.thelallantop.com/images/post/1707894537707_android-logo.webp"
                          }
                          width="25px"
                          height="25px"
                          loading="lazy"
                          alt="android-logo"
                        ></img>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://apps.apple.com/us/app/the-lallantop/id1623265607"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Play store Page"
                      >
                        <img
                          src={
                            "https://static.thelallantop.com/images/post/1707894491354_apple0logo.webp"
                          }
                          width="25px"
                          height="25px"
                          alt="apple-logo"
                          loading="lazy"
                        ></img>
                      </a>
                    </li>
                  </ul>
                  <div className="copy-right">
                    <p>© {dateYear} LALLANTOP. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>LallanKhas News</h5>
                <ul className="ul-items">
                  <li style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Hindi Satire & Humor
                    </a>
                  </li>
                  <li style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/specials"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Lallankhas Specials
                    </a>
                  </li>
                  <li style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/aasan-bhasa-me"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Breaking News
                    </a>
                  </li>
                  <li style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/political-kisse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top Political News Hindi
                    </a>
                  </li>
                  <li style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/tarikh"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top History News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/ground-report"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Real Stories News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/araamkursi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Political News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/literature"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top Literature News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/new-monk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top Persons News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/profile"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top Profiles
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/lallankhas/others"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Viral News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Entertainment News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/entertainment"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Entertainment News Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/entertainment/cinema-ke-kisse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top stories Cinema
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/entertainment/specials"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Entertainment Special News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/entertainment/movies-web-series-review"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top movies series review
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/entertainment/news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Entertainment News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Business News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/business"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Business News in Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/business/business-news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Business News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/business/specials"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Business Special News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Technology News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/technology"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Technology News in Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/technology/tech-news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Tech News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/technology/sciencekaari"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Science News & Updates
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/technology/specials"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Technology Specials News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/technology/review"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Technology Reviews in Hindi
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Top News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Breaking News Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/news/top-news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top News Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/news/latest-news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest News Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/news/social-media"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Social Media News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Election News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/election"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top Election News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/election/elections-2023"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Election News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Education News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/education"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Education News in Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/education/latest"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Education News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/education/jobs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Education Jobs News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/education/specials"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Education Specials News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/education/students"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Student Education News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Sports News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/sports"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sports News in Hindi
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/sports/specials"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sports Specials News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/sports/news"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Sports News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/sports/kisse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sports Stories News
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/sports/profile"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sports Personalities News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Oddnaari News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/oddnaari"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Top Health Tips
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/oddnaari/knowledge"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Women Health Knowledge
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/oddnaari/lifestyle"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Women Lifestyle Tips
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/oddnaari/crime"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Women Crime News
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Facts News</h5>
                <ul className="ul-items">
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/factcheck"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Fact Check
                    </a>
                  </li>
                  <li  style={{lineHeight:"12px"}}>
                    <a
                      href="https://www.thelallantop.com/all/factcheck/latest"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Latest Fact Check
                    </a>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0 px",
                  color: "#ccccccd6",
                  textDecoration: "none",
                  width: "200%",
                }}
              >
                <h5 style={{ color: "white" }}>Quick Links</h5>
                <div style={{ display: "flex", gap: "0.9rem" }}>
                  <ul className="ul-items">
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/quiz/live"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Quiz
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/bookmarks"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bookmarks
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/visualstories"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visual Stories
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/hashtags"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Hashtags
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/login"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0 px",
                  color: "#ccccccd6",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>‎ </h5>
                <div style={{ display: "flex", gap: "0.9rem" }}>
                  <ul className="ul-items">
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/about"
                        target="_blank"
                        rel="noreferrer"
                      >
                        About Us
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/terms-of-use"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/contact"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.thelallantop.com/feedback"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Feedback
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>Hot News</h5>
                <div style={{ display: "flex", gap: "0.9rem" }}>
                  <ul className="ul-items">
                    <li  style={{lineHeight:"12px"}}>Virat Kohli</li>
                    <li  style={{lineHeight:"12px"}}>Adani enterprises</li>
                    <li  style={{lineHeight:"12px"}}>Dhoni retirement</li>
                    <li  style={{lineHeight:"12px"}}>Rakshabandhan</li>
                    <li  style={{lineHeight:"12px"}}>BJP Party</li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  color: "#ccccccd6",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>‎ </h5>
                <div style={{ display: "flex", gap: "" }}>
                  <ul className="ul-items">
                    <li  style={{lineHeight:"12px"}}>ICC WorldCup Schedule</li>
                    <li  style={{lineHeight:"12px"}}>Asia Cup 2023</li>
                    <li  style={{lineHeight:"12px"}}>Chandrayan 3</li>
                    <li  style={{lineHeight:"12px"}}>Aditya L1</li>
                    <li  style={{lineHeight:"12px"}}>Corona Cases rises</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="bottom-items"
            style={{ borderTop: "5px solid white" }}
          >
            <div>
              <div
                className="bottom-items-div"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "white",
                  alignItems: "center",
                  gap: "5rem",
                  justifyContent: "center",
                }}
              >
                <img
                  className="bottom-items-logo"
                  alt="lt-logo"
                  src={
                    "https://static.thelallantop.com/images/post/1707895067525_frame.webp"
                  }
                ></img>
                <br></br>
                <div>
                  <h4 style={{ textAlign: "center" }}>Social Media</h4>
                  <ul className="social-link-list mb-4 pb-3">
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.facebook.com/thelallantop"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Facebook Page"
                      >
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://twitter.com/TheLallantop"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Twitter Page"
                      >
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.instagram.com/thelallantop/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Instagram Page"
                      >
                        <span className="icon-instagram"></span>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://www.youtube.com/channel/UCx8Z14PpntdaxCt2hakbQLQ"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Youtube Page"
                      >
                        <span className="icon-vidos"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 style={{ textAlign: "center" }}>Download Apps</h4>
                  <ul className="social-link-list bottom-app mb-4 pb-3">
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.thelallantop&hl=en&gl=US&pli=1"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Google Play Page"
                      >
                        <img
                          src={
                            "https://static.thelallantop.com/images/post/1707894537707_android-logo.webp"
                          }
                          width="25px"
                          height="25px"
                          alt="android-logo"
                        ></img>
                      </a>
                    </li>
                    <li  style={{lineHeight:"12px"}}>
                      <a
                        href="https://apps.apple.com/us/app/the-lallantop/id1623265607"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Lallantop Play Store Page"
                      >
                        <img
                          src={
                            "https://static.thelallantop.com/images/post/1707894491354_apple0logo.webp"
                          }
                          width="25px"
                          height="25px"
                          alt="apple-logo"
                        ></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="copy-right bottom-copy"
            style={{
              borderTop: "5px solid white",
              color: "white",
              paddingTop: "2rem",
              paddingBottom: "1rem",
            }}
          >
            <p>© {dateYear} LALLANTOP. All rights reserved.</p>
          </div>

          {/* <Container fluid>
            <p className="copy-right-text">
              © {dateYear} LALLANTOP. All rights reserved.
            </p>
          </Container> */}
        </footer>
      )}
      <MobileMenuBottom />
    </>
  );
};
export default Footer;
